<template>
	<div
		class="position-relative"
		:style="{ width: width ? `${width}px` : '100%' }"
	>
		<span v-if="type === 'line'" class="energy-count d-none">
			{{ props.energy }} / 100
		</span>
		<v-icon
			v-if="type === 'line'"
			:icon="mdiLightningBolt"
			color="yellow-darken-3"
			:size="24"
			:class="{ pulse: animationEnabled }"
			class="energy-line-icon"
		>
		</v-icon>
		<v-progress-linear
			:color="energyColor"
			:model-value="props.energy"
			:height="height"
			class="cursor-pointer energy rounded-lg"
			:class="
				type === 'line'
					? [{ animatedLine: animationEnabled }, lineClass]
					: [
							lineClass,
							energyClass,
							'rounded',
							{ animatedLine: animationEnabled },
					  ]
			"
		>
			<v-icon
				v-if="type === 'badge'"
				:icon="mdiLightningBolt"
				:size="Math.round(height * 0.6)"
			/>
		</v-progress-linear>
		<v-tooltip
			v-model="showTooltip"
			activator="parent"
			location="bottom"
			open-on-click
			max-width="400"
			content-class="px-4 py-3 text-caption bg-grey-darken-4"
			close-on-content-click
			open-delay="100"
			@click:outside="showTooltip = false"
		>
			{{ $t('hints.energy') }}
		</v-tooltip>
		<slot />
	</div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { mdiLightningBolt } from '@mdi/js'

type type = 'line' | 'badge'

const props = defineProps<{
	energy: number
	width?: number
	height: number
	type: type
}>()

const energyColor = ref()
const energyClass = ref()
const lineClass = ref()
const showTooltip = ref()
const animationEnabled = ref(false)

watch(
	() => props.energy,
	(val, oldVal) => {
		if (val >= 100) {
			energyColor.value = 'blue-darken-2'
			energyClass.value = 'energy-100'
			lineClass.value = 'line-100'
		} else if (val >= 75) {
			energyColor.value = 'green-darken-2'
			energyClass.value = 'energy-75'
			lineClass.value = 'line-75'
		} else if (val >= 50) {
			energyColor.value = 'yellow-darken-3'
			energyClass.value = 'energy-50'
			lineClass.value = 'line-50'
		} else if (val >= 25) {
			energyColor.value = 'orange-darken-2'
			energyClass.value = 'energy-25'
			lineClass.value = 'line-25'
		} else {
			energyColor.value = 'red-darken-1'
			energyClass.value = 'energy-0'
			lineClass.value = 'line-0'
		}

		if (val >= oldVal) {
			animationEnabled.value = true
			setTimeout(() => {
				animationEnabled.value = false
			}, 1500)
		}
	},
	{ immediate: true }
)
</script>

<style scoped>
.energy-0 {
	box-shadow: 0px 0px 5px 1px rgba(229, 57, 53, 0.5);
}
.energy-25 {
	box-shadow: 0px 0px 5px 1px rgba(245, 124, 0, 0.7);
}
.energy-50 {
	box-shadow: 0px 0px 5px 1px rgba(249, 168, 37, 0.5);
}
.energy-75 {
	box-shadow: 0px 0px 5px 1px rgba(56, 142, 60, 0.5);
}
.energy-100 {
	box-shadow: 0px 0px 5px 2px rgba(25, 118, 210, 0.5);
}
.energy-count {
	position: absolute;
	font-weight: bold;
	font-size: 14px;
	border-radius: 4px;
	padding: 0 6px;
}
.energy-line-icon {
	position: absolute;
	right: 7px;
	top: -8px;
	z-index: 1;
}
.pulse {
	animation: pulse 1.5s forwards 1;
}
@keyframes pulse {
	0%,
	40%,
	60%,
	80% {
		transform: scale(1, 1);
	}

	25%,
	50%,
	70% {
		transform: scale(1.4, 1.4);
	}

	100% {
		transform: scale(1, 1);
	}
}
</style>

<style>
.energy.animatedLine {
	overflow: visible !important;
}
.energy.animatedLine.line-0 .v-progress-linear__determinate {
	--shadow: 0px 0px 7px 3px rgba(229, 57, 53, 0.7);
}
.energy.animatedLine.line-25 .v-progress-linear__determinate {
	--shadow: 0px 0px 7px 3px rgba(245, 123, 0, 0.7);
}
.energy.animatedLine.line-50 .v-progress-linear__determinate {
	--shadow: 0px 0px 7px 3px rgba(249, 168, 37, 0.7);
}
.energy.animatedLine.line-75 .v-progress-linear__determinate {
	--shadow: 0px 0px 7px 3px rgba(56, 142, 60, 0.7);
}
.energy.animatedLine.line-100 .v-progress-linear__determinate {
	--shadow: 0px 0px 7px 3px rgba(25, 118, 210, 0.7);
}
.energy.animatedLine .v-progress-linear__determinate {
	border-radius: 24px 0 0 24px;
	animation: progress 1.5s infinite;
}
@keyframes progress {
	20%,
	70% {
		box-shadow: var(--shadow);
	}
	100% {
		box-shadow: none;
	}
}
</style>
