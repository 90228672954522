<template>
	<v-bottom-navigation id="mobile-nav" :grow="true" :elevation="0">
		<v-btn
			v-if="!user"
			:ripple="false"
			:to="{ path: localePath('/'), force: true }"
			:exact="true"
		>
			<v-icon :icon="mdiHome" size="17" />
			<span>{{ $t('nav.desc') }}</span>
		</v-btn>

		<v-btn
			v-if="!user"
			:ripple="false"
			:to="{ path: localePath('/login'), force: true }"
		>
			<v-icon :icon="mdiLogin" size="17" />
			<span>{{ $t('nav.login') }}</span>
		</v-btn>

		<v-btn
			v-if="user"
			:ripple="false"
			:to="{ path: localePath('/vote'), force: true }"
		>
			<v-icon :icon="mdiCheckAll" size="17" />
			<span>{{ $t('nav.vote') }}</span>
		</v-btn>

		<v-btn
			v-if="user"
			:ripple="false"
			:to="{ path: localePath('/my-tests'), force: true }"
		>
			<v-icon :icon="mdiAccountDetails" size="17" />
			<span>{{ $t('nav.myTests') }}</span>
		</v-btn>

		<v-btn
			v-if="!user"
			:ripple="false"
			@click="dialogStore.openDialog('settings')"
			class="mobile-nav-no-active"
		>
			<img
				height="17"
				:alt="locale"
				class="mb-1 rounded-sm pa-1"
				:src="`/images/flags/${locale}.svg`"
			/>
			<span>{{ $t('nav.language') }}</span>
		</v-btn>

		<v-btn :ripple="false" size="small" class="mobile-nav-no-active">
			<v-icon :icon="mdiMenu" size="17" />
			<v-menu activator="parent" location="top">
				<UserMenu />
			</v-menu>
			<span>{{ $t('nav.menu') }}</span>
		</v-btn>
	</v-bottom-navigation>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
	mdiAccountDetails,
	mdiCheckAll,
	mdiHome,
	mdiLogin,
	mdiMenu,
} from '@mdi/js'
import UserMenu from '../components/UserMenu.vue'
import { useDialogStore } from '../stores/dialogStore'
import { useUserStore } from '../stores/userStore'
import { localePath } from '../composables/useLanguage'
const user = computed(() => useUserStore().user)
const { locale } = useI18n({ useScope: 'global' })
const dialogStore = useDialogStore()
</script>

<style scoped></style>

<style>
.v-bottom-navigation {
	height: calc(
		56px + env(safe-area-inset-bottom) + var(--tg-safe-area-inset-bottom, 0px) +
			var(--tg-content-safe-area-inset-bottom, 0px)
	) !important;
	padding-bottom: calc(
		env(safe-area-inset-bottom) + var(--tg-safe-area-inset-bottom, 0px) +
			var(--tg-content-safe-area-inset-bottom, 0px)
	);
	background: rgb(var(--v-theme-background)) !important;
	border-top: solid 1px rgba(var(--v-theme-on-background), 0.07) !important;
}

.v-bottom-navigation__content .v-btn--active > .v-btn__overlay,
.v-bottom-navigation__content .v-btn:hover > .v-btn__overlay {
	--opacity: var(--v-mobile-nav-opacity) !important;
	opacity: 0 !important;
}
.v-bottom-navigation__content .v-btn__content {
	opacity: 0.9 !important;
}
.v-bottom-navigation__content .v-btn--active .v-btn__content {
	color: rgb(var(--v-theme-mobile-nav-active));
	opacity: 1 !important;
}
.v-bottom-navigation__content .v-btn {
	font-size: 0.75rem !important;
}
.v-bottom-navigation__content .v-btn:focus-visible::after {
	border: none !important;
}
.v-bottom-navigation .mobile-nav-no-active.v-btn--active > .v-btn__overlay {
	opacity: 0 !important;
}
.v-bottom-navigation .v-btn--active.mobile-nav-no-active .v-btn__content {
	color: inherit;
}
.v-bottom-navigation__content i {
	margin-bottom: 4px;
}
</style>
